<template>
  <v-container class="grey lighten-5">
    <v-row>
      <v-col col-12 class="main-col-margin">
        <v-card>
          <v-card-title>
            <v-icon large left> mdi-check-all</v-icon>
            <span class="title">{{ $t('project.filters.title') }}</span>

            <v-btn
              class="button-right"
              icon
              @click="showFilters = !showFilters"
            >
              <v-icon>{{
                showFilters ? 'mdi-chevron-up' : 'mdi-chevron-down'
              }}</v-icon>
            </v-btn>
          </v-card-title>
          <v-expand-transition>
            <div v-show="showFilters">
              <v-container class="grey lighten-5">
              <v-row>
                <v-col cols="12" sm="6">
                  <v-date-picker
                    v-model="filters.dateRange"
                    range
                    full-width
                    :selected-items-text=selectedDates
                  ></v-date-picker>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-card color="#385F73" dark outlined height='100%'>
                    <v-card-title class="headline">
                      {{ $t('project.filters.status.title') }}
                    </v-card-title>

                    <v-card-subtitle>
                      {{ $t('project.filters.status.subtitle') }}
                    </v-card-subtitle>

                    <v-card-text>
                      <v-switch
                        class="v-card-switch-no-padding-top"
                        v-for="status in statusFilters"
                        :key="'status_' + status"
                        v-model="filters.status"
                        color="primary"
                        :label="$t(`project.filters.status.status.${status}`)"
                        :value="status"
                        :hide-details="true"
                      ></v-switch>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" xs="6" class="main-col-margin">
                  <v-text-field
                    class="v-text-no-padding-top"
                    :label="$t('project.filters.referenceNumber')"
                    prepend-inner-icon="mdi-map-marker"
                    clearable
                    v-model="filters.reference"
                  ></v-text-field>

                  <v-text-field
                    class="v-text-no-padding-top"
                    :label="$t('project.filters.email')"
                    prepend-inner-icon="mdi-map-marker"
                    clearable
                    v-model="filters.email"
                  ></v-text-field>

                  <v-autocomplete
                    style="margin-top: 10px"
                    v-model="filters.countries"
                    :items="countries"
                    dense
                    filled
                    :label="$t('project.filters.countries')"
                    multiple
                    item-value="id"
                    item-text="nicename"
                  ></v-autocomplete>

                  <v-autocomplete
                    style="margin-top: 10px"
                    v-model="filters.vendors"
                    :items="vendors"
                    dense
                    filled
                    :label="$t('project.filters.vendors')"
                    multiple
                    item-value="id"
                    item-text="name"
                  ></v-autocomplete>
                </v-col>
                
              </v-row>
              </v-container>
              <v-card-actions>
                <v-btn color="success" @click="getFilteredProjects()">
                  {{ $t('project.filters.controlls.apply') }}
                </v-btn>
                <v-btn color="error" @click="clearFilters()">
                  {{ $t('project.filters.controlls.clear') }}
                </v-btn>
              </v-card-actions>
            </div>
          </v-expand-transition>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col col-12 class="main-col-margin">
        <v-card>
          <v-card-title class="main-card-title-offset">
            <v-icon large left color="white"> mdi-format-list-bulleted </v-icon>
            <span class="title">{{ $t('project.projects.title') }}</span>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="projects"
            :sort-by="[]"
            :sort-desc="[false, true]"
            hide-default-footer
            :items-per-page="10"
            multi-sort
            class="elevation-1"
            :loading="isLoading"
            :loading-text="$t('project.projects.loading')"
          >
            <template v-slot:[`item.created_at`]="props">
              {{
                moment.utc(props.item.created_at).format('DD-MM-YYYY HH:mm:ss')
              }}
            </template>
            <template v-slot:[`item.controls`]="props">
              <v-btn
                class="mx-2"
                fab
                dark
                small
                color="warning"
                @click="editProject(props.item)"
              >
                <v-icon dark>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                class="mx-2"
                fab
                dark
                small
                color="error"
                @click="openDeleteProjectDialog(props.item)"
              >
                <v-icon dark>mdi-delete</v-icon>
              </v-btn>

              <v-btn
                class="mx-2"
                fab
                dark
                small
                color="primary"
                @click="copyProjectDialog(props.item)"
              >
                <v-icon dark>mdi-content-copy</v-icon>
              </v-btn>
            </template>
          </v-data-table>
          <v-card-actions v-if="links.length > 0">
            <div v-for="(link, index) in links" :key="'link'+ index">
              <v-btn
                v-if="index == 0"
                @click="fetchUserProjectsPagination(link.url)"
                class="mx-2"
                fab
                dark
                small
                color="primary"
              >
                <v-icon>
                  mdi-arrow-left-bold
                </v-icon>
              </v-btn>
              <v-btn
                v-else-if="index == links.length-1"
                @click="fetchUserProjectsPagination(link.url)"
                class="mx-2"
                fab
                dark
                small
                color="primary"
              >
                <v-icon>
                  mdi-arrow-right-bold
                </v-icon>
              </v-btn>
              <v-btn
                v-else
                @click="fetchUserProjectsPagination(link.url)"
                class="mx-2"
                fab
                dark
                small
                color="primary"
              >
                {{ index }}
              </v-btn>
            </div>
          </v-card-actions>
          <v-card-actions v-if="linksFilters.length > 0">
            <div v-for="(link, index) in linksFilters" :key="'link_filters'+ index">
              <v-btn
                v-if="index == 0"
                @click="getFilteredProjectsPagination(link.url)"
                class="mx-2"
                fab
                dark
                small
                color="primary"
              >
                <v-icon>
                  mdi-arrow-left-bold
                </v-icon>
              </v-btn>
              <v-btn
                v-else-if="index == linksFilters.length-1"
                @click="getFilteredProjectsPagination(link.url)"
                class="mx-2"
                fab
                dark
                small
                color="primary"
              >
                <v-icon>
                  mdi-arrow-right-bold
                </v-icon>
              </v-btn>
              <v-btn
                v-else
                @click="getFilteredProjectsPagination(link.url)"
                class="mx-2"
                fab
                dark
                small
                color="primary"
              >
                {{ index }}
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-dialog v-model="dialog" persistent max-width="300">
        <v-card>
          <v-card-title class="headline">{{ $t('project.projects.delete.areYouSure.title') }}</v-card-title>
          <v-card-text>
            {{ $t('project.projects.delete.areYouSure.description') }}
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-row class="text-center" style="margin: 0px">
              <v-col>
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="error"
                  text
                  @click="deleteProject()"
                >
                  <v-icon dark>mdi-delete</v-icon>
                  {{ $t('project.projects.delete.areYouSure.controlls.delete') }}
                </v-btn>
              </v-col>
              <v-col>
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="primary"
                  text
                  @click="closeDeleteProjectDialog()"
                >
                  <v-icon dark>mdi-close-circle</v-icon>
                  {{ $t('project.projects.delete.areYouSure.controlls.cancel') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <error-handler :error="error"></error-handler>

    <v-dialog v-model="copy.dialog.areYouSure" persistent max-width="300">
        <v-card>
          <v-card-title class="headline">{{ $t('project.projects.copy.areYouSure.title') }}</v-card-title>
          <v-card-text>
            {{ $t('project.projects.copy.areYouSure.description') }}
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-row class="text-center" style="margin: 0px">
              <v-col>
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="primary"
                  text
                  @click="copyProject()"
                >
                  <v-icon dark>mdi-content-copy</v-icon>
                  {{ $t('project.projects.copy.areYouSure.controlls.ok') }}
                </v-btn>
              </v-col>
              <v-col>
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="error"
                  text
                  @click="copy.dialog.areYouSure = !copy.dialog.areYouSure"
                >
                  <v-icon dark>mdi-close-circle</v-icon>
                  {{ $t('project.projects.copy.areYouSure.controlls.cancel') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>

    <v-dialog v-model="copy.dialog.loading" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          {{ $t('project.projects.copy.progress.description') }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="copy.dialog.status" persistent width="300">
      <v-card class="mx-auto" max-width="344">
        <v-card-text>
          <p class="display-1 text--primary text-center">
            {{ $t('project.projects.copy.success.title') }}
          </p>
          <div class="text--primary text-center">
            {{ $t('project.projects.copy.success.description') }}
          </div>
        </v-card-text>
        <v-card-actions>
          <a
            class="mx-auto"
          >
            <v-btn
              @click="copy.dialog.status = !copy.dialog.status"
              class="accent-4 white--text"
              color="success"
            >
              {{ $t('project.projects.copy.success.controlls.ok') }}
            </v-btn>
          </a>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>

export default {
  name: 'Projects',
  data () {
    return {
      isLoading: true,
      error: null,
      showFilters: false,
      headers: [],
      projects: [],
      links: [],
      linksFilters: [],
      filters: {
        dateRange: [],
        status: [],
        reference: '',
        email: '',
        countries: [],
        vendors: [],
      },
      dialog: false,
      copy: {
        project: null,
        dialog: {
          areYouSure: false,
          loading: false,
          status: false
        }
      },
      deletingProject: null
    }
  },
  computed: {
    isUserAdmin() {
      return this.$store.getters.getUserData.admin
    },
    applicationData () {
      return this.$store.state.applicationData
    },
    statusFilters () {
      if (this.applicationData) {
        return this.applicationData.project.constants.statuses
      }
      return []
    },
    countries () {
      if (this.applicationData) {
        return this.applicationData.countries
      }
      return []
    },
    vendors () {
      if (this.applicationData) {
        return this.applicationData.vendors
      }
      return []
    },
    selectedDates () {
      if (this.filters.dateRange.length != 2) {
        return this.$root.$t('project.filters.date.selectDate')
      } else {
        return this.$root.$t('project.filters.date.range', {
          from: this.$root.moment(this.filters.dateRange[0]).format('DD.MM.YYYY'),
          to: this.$root.moment(this.filters.dateRange[1]).format('DD.MM.YYYY')
        })
      } 
    }
  },
  mounted () {
    this.$store.dispatch('loadApplicationData')
    this.fetchUserProjects()

    this.headers = [
        { text: this.$t('project.projects.table.labels.status'), align: 'start', value: 'status' },
        { text: this.$t('project.projects.table.labels.refnum'), value: 'reference_number' },
        { text: this.$t('project.projects.table.labels.email'), value: 'customer.email' },
        { text: this.$t('project.projects.table.labels.country'), value: 'country.nicename' },
        { text: this.$t('project.projects.table.labels.created'), value: 'created_at' },
        { text: this.$t('project.projects.table.labels.name'), value: 'name' },
      ];

      if(this.isUserAdmin == true) {
        this.headers.push({ text: this.$t('project.projects.table.labels.vendor'), value: 'vendor.name' })
      }

      this.headers.push(
        {
          text: this.$t('project.projects.table.labels.actions'),
          value: 'controls',
          sortable: false,
          align: 'center'
        }
      )
  },
  methods: {
    fetchUserProjects () {
      this.links = [];
      this.linksFilters = [];

      this.$axios({
        method: 'get',
        url: this.$globals.api.project.getAll
      })
        .then(response => {
          this.projects = response.data.data.data
          this.links = response.data.data.links
          this.isLoading = false
        })
        .catch(error => {
          this.error = error.response
        })
    },
    fetchUserProjectsPagination (url) {
      if (!url) return
      
      this.links = [];
      this.linksFilters = [];
      
      this.$axios({
        method: 'get',
        url: url
      })
        .then(response => {
          this.projects = response.data.data.data
          this.links = response.data.data.links
          this.isLoading = false
        })
        .catch(error => {
          this.error = error.response
        })
    },
    getFilteredProjects() {
      this.links = [];
      this.linksFilters = [];
      
      this.isLoading = true

      this.$axios({
        method: 'post',
        url: this.$globals.api.project.getFiltered,
        data: this.filters
      })
        .then(response => {
          this.projects = response.data.data.data
          this.linksFilters = response.data.data.links
          this.isLoading = false
        })
        .catch(error => {
          this.error = error.response
        })
    },
    getFilteredProjectsPagination(url) {
      if (!url) return

      this.links = [];
      this.linksFilters = [];
      
      this.isLoading = true

      this.$axios({
        method: 'post',
        url: url,
        data: this.filters
      })
        .then(response => {
          this.projects = response.data.data.data
          this.linksFilters = response.data.data.links
          this.isLoading = false
        })
        .catch(error => {
          this.error = error.response
        })
    },
    clearFilters(){
      this.filters = {
        dateRange: [],
        status: [],
        reference: '',
        email: '',
        countries: [],
        vendors: [],
      }
      this.fetchUserProjects()
    },
    editProject (project) {
      this.$router.push({ name: 'project/edit', params: { id: project.id } })
    },
    openDeleteProjectDialog (project) {
      this.dialog = true
      this.deletingProject = project
    },
    closeDeleteProjectDialog () {
      this.dialog = false
      this.deletingProject = null
    },
    deleteProject () {
      this.$axios({
        method: 'post',
        url: this.$globals.api.project.delete + this.deletingProject.id
      })
        .then(() => {
          this.deletingProject = null
          this.dialog = false
          this.isLoading = true
          this.fetchUserProjects()
        })
        .catch(error => {
          this.error = error.response
        })
    },
    copyProjectDialog (project) {
      this.copy.project = project
      this.copy.dialog.areYouSure = true
    },
    copyProject() {
      this.copy.dialog.areYouSure = false
      this.copy.dialog.loading = true
      this.$axios({
        method: 'post',
        url: this.$globals.api.project.clone + this.copy.project.id
      })
        .then(() => {
          this.copy.dialog.loading = false
          this.copy.dialog.status = true
          this.fetchUserProjects()
        })
        .catch(error => {
          this.error = error.response
        })
    }
  }
}
</script>
